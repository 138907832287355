import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationModalService } from '../../../../shared/components/confirmation/services/confirmation-modal.service';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UploadComponent, UploadComponentConfig } from '../../../../shared/components/upload/upload.component';
import {
  AgreementQueryResourceService,
  ConstructionSite,
  ConstructionSiteCommandResourceService,
  Lot
} from '../../../../shared/api';
import { APP_EVENTS } from '../../../../shared/event/event.enum';
import { JhiEventManager } from 'ng-jhipster';
import { LotFormComponent } from '../../../lot/detail/form/lot-form.component';
import { EXTENSION_REGEX } from '../../../../app.constants';

export const PICTURE_MIME_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];

@Component({
  selector: 'fc-construction-site-form',
  templateUrl: './construction-site-form.component.html',
  styleUrls: ['./construction-site-form.component.scss'],
  providers: [CookieService]
})
export class ConstructionSiteFormComponent implements OnInit {
  pictureFile: File;
  uploadConfig: UploadComponentConfig;
  constructionSite: ConstructionSite;
  wip: boolean;
  agreementMode: string;
  isPictureFormDisplayed = true;

  @ViewChild('uploadCmp', { static: true }) uploadCmp: UploadComponent;

  constructor(
    public modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private cookieService: CookieService,
    private constructionSiteCommandResourceService: ConstructionSiteCommandResourceService,
    private confirmationModal: ConfirmationModalService,
    private toasterService: ToastrService,
    private translateService: TranslateService,
    private agreementQueryResourceService: AgreementQueryResourceService,
    private eventManager: JhiEventManager
  ) {
    this.constructionSite = { name: null, address: null, client: null };
    this.wip = false;
  }

  ngOnInit() {
    this.uploadConfig = {
      acceptedMimeTypes: PICTURE_MIME_TYPES,
      icon: 'picture',
      formatTextKey: 'construction-site.components.form.labels.upload'
    };

    if (this.constructionSite && this.constructionSite.pictureUrl) {
      this.uploadConfig.previewUrl = this.constructionSite.pictureUrl.toString();
    }

    if (this.isEdition()) {
      this.uploadCmp.clearErrors();
    } else {
      if (this.constructionSite) {
        this.constructionSite.isOnExclVatAmount = true;
      }
      this.isPictureFormDisplayed = false;
    }

    if (this.constructionSite?.id) {
      this.agreementQueryResourceService.getLatestAgreement(this.constructionSite.id).subscribe((agreement) => {
        this.agreementMode = agreement.mode;
      });
    }
  }

  openLotForm() {
    const modalRef = this.modalService.open(LotFormComponent, { windowClass: 'fullscreen' });
    modalRef.componentInstance.constructionSite = { ...this.constructionSite };
    modalRef.componentInstance.site = {
      isSiteCreated: false,
      picture: this.pictureFile
    };
    /* istanbul ignore next */
    modalRef.componentInstance.addSite?.subscribe((event) =>
      this.createEvent(event.constructionSite, event.lot, event.picture)
    );
    /* istanbul ignore next */
    modalRef.componentInstance.closeParentModal?.subscribe(() => this.activeModal.dismiss());
    modalRef.result.then(() => {});
  }

  createEvent(constructionSiteToAdd: ConstructionSite, lot: Lot, picture: Blob) {
    this.wip = true;

    const constructionSiteObservable: Observable<ConstructionSite> = this.constructionSiteCommandResourceService.addSite(
      constructionSiteToAdd,
      lot,
      picture
    ) as Observable<ConstructionSite>;

    constructionSiteObservable.subscribe(
      (result) => {
        this.constructionSite = result;
        this.toasterService.success(
          this.translateService.instant('construction-site.components.form.messages.save.success')
        );
        this.activeModal.dismiss('close');
        this.eventManager.broadcast(APP_EVENTS.CONSTRUCTION_SITE_ADDED);
        this.eventManager.broadcast(APP_EVENTS.EXPENSES_UPDATED);
        this.wip = false;
      },
      () => {
        this.toasterService.error(
          this.translateService.instant('construction-site.components.form.messages.save.error')
        );
        this.wip = false;
      }
    );
  }

  update() {
    this.wip = true;

    let constructionSiteObservable: Observable<ConstructionSite>;

    if (this.pictureFile) {
      this.pictureFile = new File(
        [this.pictureFile],
        'logo.' + this.pictureFile.name.match(new RegExp(EXTENSION_REGEX)),
        {
          type: this.pictureFile.type
        }
      );
    }

    constructionSiteObservable = this.constructionSiteCommandResourceService.updateSiteWithPic1(
      this.constructionSite.id,
      Object.assign({}, this.constructionSite, { members: undefined }),
      this.pictureFile
    );

    constructionSiteObservable.subscribe(
      (result) => {
        this.constructionSite = result;
        this.toasterService.success(
          this.translateService.instant('construction-site.components.form.messages.save.success')
        );
        this.activeModal.close({ constructionSite: this.constructionSite, isEdition: true });
        this.eventManager.broadcast(APP_EVENTS.CONSTRUCTION_SITE_ADDED);
        this.eventManager.broadcast(APP_EVENTS.EXPENSES_UPDATED);
      },
      () => {
        this.toasterService.error(
          this.translateService.instant('construction-site.components.form.messages.save.error')
        );
        this.wip = false;
      }
    );
  }

  delete() {
    this.confirmationModal
      .confirm(
        'construction-site.components.delete.title',
        'construction-site.components.delete.guide',
        this.translateService.instant('app.delete'),
        'confirmation.form.buttons.delete',
        null
      )
      .subscribe((result) => {
        if (result) {
          this.constructionSiteCommandResourceService.deleteSite(this.constructionSite.id).subscribe(
            () => {
              this.activeModal.close(null);
              this.toasterService.success(
                this.translateService.instant('construction-site.components.form.messages.delete.success')
              );
            },
            () =>
              this.toasterService.error(
                this.translateService.instant('construction-site.components.form.messages.delete.error')
              )
          );
        }
      });
  }

  isEdition(): boolean {
    return Boolean(this.constructionSite && this.constructionSite.id);
  }

  sendEmail($event: MouseEvent) {
    $event.stopPropagation();
    const email = 'support@thefaircost.com';
    window.location.href = 'mailto:' + email;
    return email;
  }
}
