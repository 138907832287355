/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Agreement } from './agreement';
import { MemberId } from './memberId';
import { ExpensesStats } from './expensesStats';
import { Organisation } from './organisation';
import { ConstructionSiteStats } from './constructionSiteStats';
import { FundingAppealsStats } from './fundingAppealsStats';
import { Member } from './member';


export interface ConstructionSite { 
    createdDate?: string;
    createdBy?: string;
    lastModifiedDate?: string;
    lastModifiedBy?: string;
    id?: string;
    name?: string;
    billingAddress?: string;
    purchaseOrder?: string;
    siren?: string;
    address?: string;
    clientName?: string;
    client?: Organisation;
    generalContractor?: Organisation;
    duration?: number;
    expectedStartDate?: string;
    pictureUrl?: string;
    state?: ConstructionSite.StateEnum;
    totalAmount?: number;
    includedAmount?: number;
    reference?: string;
    reference2?: string;
    reportNumber?: number;
    generateBill?: boolean;
    isOnExclVatAmount?: boolean;
    nbRegisteredMail?: number;
    members?: Array<MemberId>;
    manager?: Member;
    expensesStats?: ExpensesStats;
    constructionSiteStats?: ConstructionSiteStats;
    latestAgreement?: Agreement;
    fundingAppealsStats?: FundingAppealsStats;
    signatoryPercent?: number;
    isClosed?: boolean;
    isArchived?: boolean;
}
export namespace ConstructionSite {
    export type StateEnum = 'ACTIVE' | 'DELETED';
    export const StateEnum = {
        Active: 'ACTIVE' as StateEnum,
        Deleted: 'DELETED' as StateEnum
    };
}


