import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MemberQueryResourceService } from '../../../shared/api';
import { uniqBy } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'fc-email-select',
  templateUrl: './email-select.component.html',
  styleUrls: ['./email-select.component.scss']
})
export class EmailSelectComponent implements OnInit {
  @Input() email: {
    email: string;
    isFormatValid: boolean;
    isMxValid: boolean;
    isDisposable: boolean;
    isScoreValid: boolean;
    hasToSendInvitation: boolean;
  };
  @Input() index: number;
  @Output() updateEmail: EventEmitter<{ email: string; idx: number }> = new EventEmitter();
  private subject: Subject<{ email: string }> = new Subject<{ email: string }>();
  displayProposition = false;
  currentEmail = '';
  members = [];

  constructor(private memberQueryResourceService: MemberQueryResourceService) {}

  ngOnInit() {
    this.subject.pipe(debounceTime(1500)).subscribe((params) => {
      this.selectEmail(this.currentEmail);
    });
  }

  onKeyUp(event: any) {
    this.displayProposition = true;
    this.subject.next();
  }

  selectEmail(email) {
    this.updateEmail.emit({
      email,
      idx: this.index
    });
  }

  selectElement(idx: number) {
    this.displayProposition = false;
    this.currentEmail = this.members[idx].email;
    this.subject.next();
  }
}
