/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BankInformation } from './bankInformation';


export interface FundingAppealRequest { 
    billLotId?: string;
    fundingAppealBillNum?: number;
    isDeductedParticipationFirstModel?: boolean;
    fundingAppealLotAsset?: string;
    isClosing?: boolean;
    closingMode?: FundingAppealRequest.ClosingModeEnum;
    bankInformation?: BankInformation;
    hasDownloadBills?: boolean;
    displayEmail?: boolean;
    displayPhone?: boolean;
    displayBankInformation?: boolean;
    numberLabel?: FundingAppealRequest.NumberLabelEnum;
    displayParentRegistration?: boolean;
    amount: number;
    billDeadlineDate?: string;
    billSendingDate?: string;
    amountWithTaxes?: number;
    billToDate?: string;
    participationPercent?: number;
    isCumulatedAmountActive?: boolean;
    cumulatedSituationAmountExclTaxes?: number;
    cumulatedAmountExclTaxes?: number;
    percent: number;
    lots?: Array<string>;
    disabledLots?: Array<string>;
    prepaidLots?: Array<string>;
    sendToProjectOwner?: boolean;
    sendToGeneralContractor?: boolean;
    updatedBillNumbers?: { [key: string]: string; };
}
export namespace FundingAppealRequest {
    export type ClosingModeEnum = 'MODE1' | 'MODE2';
    export const ClosingModeEnum = {
        Mode1: 'MODE1' as ClosingModeEnum,
        Mode2: 'MODE2' as ClosingModeEnum
    };
    export type NumberLabelEnum = 'FUNDING_APPEALS' | 'BILL_NUMBER' | 'ASSET';
    export const NumberLabelEnum = {
        FundingAppeals: 'FUNDING_APPEALS' as NumberLabelEnum,
        BillNumber: 'BILL_NUMBER' as NumberLabelEnum,
        Asset: 'ASSET' as NumberLabelEnum
    };
}


