import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlContainer, FormControl, NG_VALUE_ACCESSOR, NgForm, ValidationErrors } from '@angular/forms';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone/lib/ngx-dropzone/ngx-dropzone.component';
import { AbstractControlValueAccessor } from '../form/abstract-control-value-accessor';
import { PDF_MIME_TYPES } from '../../../app.constants';
import { APP_EVENTS } from '../../event/event.enum';
import { Subscription } from 'rxjs';
import { JhiEventManager } from 'ng-jhipster';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { RejectedFile } from 'ngx-dropzone/lib/ngx-dropzone.service';
import { isNullOrUndefined } from 'util';

export interface UploadComponentConfig {
  previewUrl?: string;
  maxFileSize?: number;
  acceptedMimeTypes?: string[];
  icon?: string;
  isUnderViewer?: boolean;
  formatTextKey?: string;
  multiple?: boolean;
  hidePreview?: boolean;
  notRemoveFilesOnDestroy?: boolean;
  hideZone?: boolean;
}

@Component({
  selector: 'fc-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadComponent),
      multi: true
    }
  ],
  // Attach the current select to the parent form
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class UploadComponent extends AbstractControlValueAccessor<File | File[]> implements OnInit, OnDestroy {
  @Input() config: UploadComponentConfig;
  @Input() previousFiles: File[];
  @Input() page = 0;
  @Output() predict = new EventEmitter();
  @Output() filesChangedOrRemoved: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() afterLoadCompleteEvent: EventEmitter<number> = new EventEmitter<number>();
  fileErrors: ValidationErrors = {};
  fileControl: FormControl; // File control to handle form validation
  pageNumber = 0;
  private subscriptions: Subscription[] = [];
  constructor(private eventManager: JhiEventManager) {
    super();
    this.config = {};

    /**
     * Add an angular validator on the file input
     * {@see fileValidator}
     */
    this.fileControl = new FormControl('', [this.fileValidator.bind(this)]);
  }

  ngOnDestroy() {
    if (!this.config.notRemoveFilesOnDestroy) {
      this.removeAllFiles();
    }
  }

  ngOnInit() {
    /* istanbul ignore next */
    this.eventManager.subscribe(APP_EVENTS.UPLOAD_REMOVE_FILE, () => this.removeAllFiles());

    if (!this.config.acceptedMimeTypes) {
      this.config.acceptedMimeTypes = [];
    }

    if (!this.config.icon) {
      this.config.icon = 'file';
    }

    if (isNullOrUndefined(this.config.hideZone)) {
      this.config.hideZone = true;
    }

    this.config.isUnderViewer = this.config.isUnderViewer ? this.config.isUnderViewer : false;
  }

  clearErrors() {
    this.fileErrors = null;
    this.fileControl.updateValueAndValidity();
  }

  selectFile(event: NgxDropzoneChangeEvent) {
    this.fileErrors = {};

    if (event.rejectedFiles && event.rejectedFiles.length > 0) {
      event.rejectedFiles.forEach((rejectedFile: RejectedFile & { reason: string }) => {
        if (rejectedFile.reason === 'size') {
          this.fileErrors.invalidFileSize = true;
        }
        if (rejectedFile.reason === 'type') {
          this.fileErrors.invalidFileType = true;
        }
      });
    } else if (event.addedFiles && event.addedFiles.length > 0) {
      if (this.isIE11()) {
        event.addedFiles.forEach((file: File & { reason: string }) => {
          const ext = file.name.substring(file.name.indexOf('.') + 1);
          if (!this.config.acceptedMimeTypes.some((type) => type.includes(ext))) {
            this.fileErrors.invalidFileType = true;
          }
        });
      }

      if (!this.fileErrors.invalidFileType) {
        // Read the file to show a preview
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(event.addedFiles[0]);
        /* istanbul ignore next */
        reader.onload = () => {
          /* istanbul ignore next */
          this.config.previewUrl = reader.result as string;
        };

        if (!this.config.multiple) {
          this.value = event.addedFiles[0];
        } else {
          this.value = event.addedFiles;
        }
      }
    }

    this.fileControl.markAsDirty();
    this.fileControl.updateValueAndValidity();
    this.predict.emit(true);
    this.filesChangedOrRemoved.emit(true);
  }

  isFilePdf(): boolean {
    return this.config && this.config.acceptedMimeTypes.some((type) => PDF_MIME_TYPES.includes(type));
  }

  isFileImage(): boolean {
    return this.config && this.config.acceptedMimeTypes.some((type) => type.includes('image/'));
  }

  removeFile(event: Event, file: File) {
    event.preventDefault();
    this.value = (this.value as File[]).filter((selectedFile) => selectedFile !== file);
  }

  removeAllFiles() {
    this.fileErrors = {};
    this.fileControl.markAsPristine();
    this.fileControl.updateValueAndValidity();
    this.value = null;
    this.config.previewUrl = null;
    this.filesChangedOrRemoved.emit(true);
  }

  isIE11(): boolean {
    return Boolean(window.MSInputMethodContext && (document as any).documentMode);
  }

  /**
   * From control validator to test files type are correct
   */
  private fileValidator(): { [key: string]: boolean } | null {
    if (this.fileErrors) {
      return this.fileErrors;
    }
    return null;
  }

  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pageNumber = pdf.numPages;
    this.afterLoadCompleteEvent.emit(this.pageNumber);
  }
}
