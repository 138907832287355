import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmationModalService } from '../../../../shared/components/confirmation/services/confirmation-modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { APP_EVENTS } from '../../../../shared/event/event.enum';
import { JhiEventManager } from 'ng-jhipster';
import {
  ConstructionSite,
  Invitation,
  Invitations,
  Lot,
  LotCommandResourceService,
  LotMemberCommandResourceService,
  LotMemberQueryResourceService,
  Member,
  MemberCommandResourceService,
  MemberId,
  MemberQueryResourceService,
  OrganisationRegistrationCommandResourceService,
  OrganisationRegistrationQueryResourceService,
  SirenManagerLabel
} from '../../../../shared/api';
import { AccountService } from '../../../account/account.service';
import { Account } from '../../../account/shared/models/account';
import { forkJoin, Observable } from 'rxjs';
import RoleEnum = Lot.RoleEnum;
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LotServiceService } from '../../lot-service.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'fc-lot-form',
  templateUrl: './lot-form.component.html',
  styleUrls: ['./lot-form.component.scss']
})
export class LotFormComponent implements OnInit {
  @ViewChild(NgForm) form: NgForm;
  lot: Lot;
  originalLot: Lot;
  organisationLabel: string;
  constructionSite: ConstructionSite;
  addAnotherLot = false;
  invitedEmails: {
    email: string;
    isFormatValid: boolean;
    isMxValid: boolean;
    isDisposable: boolean;
    isScoreValid: boolean;
    hasToSendInvitation: boolean;
  }[] = [];
  wip = false;
  private account: Account;
  private revokedMembers: MemberId[];
  members: Member[] = [];
  authorityRoles = [RoleEnum.Manager, RoleEnum.Member];
  role: RoleEnum;
  site: {
    isSiteCreated: boolean;
    picture: File;
  } = {
    isSiteCreated: true,
    picture: undefined
  };

  @Output() closeParentModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() addSite: EventEmitter<{ constructionSite: ConstructionSite; lot: Lot; picture: Blob }> = new EventEmitter<{
    constructionSite: ConstructionSite;
    lot: Lot;
    picture: Blob;
  }>();

  constructor(
    public activeModal: NgbActiveModal,
    private lotCommandResourceService: LotCommandResourceService,
    private confirmationService: ConfirmationModalService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private toasterService: ToastrService,
    private memberCommandResourceService: MemberCommandResourceService,
    private memberQueryResourceService: MemberQueryResourceService,
    private lotMemberCommandResourceService: LotMemberCommandResourceService,
    private lotMemberQueryResourceService: LotMemberQueryResourceService,
    private lotService: LotServiceService,
    private eventManager: JhiEventManager,
    private accountService: AccountService,
    private router: Router,
    private organisationRegistrationCommandResourceService: OrganisationRegistrationCommandResourceService
  ) {
    this.lot = { name: null, description: null, role: null };
    this.revokedMembers = [];
  }

  getDefaultMemberRole(members: Member[]) {
    return members && members.some((member) => member.role === RoleEnum.Manager) ? RoleEnum.Manager : RoleEnum.Member;
  }

  ngOnInit() {
    // Get construction site from resolver
    if (this.site.isSiteCreated) {
      this.constructionSite =
        this.route.parent.parent.parent.snapshot.data.constructionSite ||
        this.route.parent.parent.parent.parent.snapshot.data.constructionSite;

      this.lot = this.route.parent.snapshot.data.lot || { name: null, description: null };
      this.lot.fixedPercent = this.lot.fixedPercent ? this.lot.fixedPercent * 100 : this.lot.fixedPercent;
      if (!this.lot.id) {
        this.lot.included = true;
      }
    }

    this.accountService.get().subscribe((account) => (this.account = account));

    this.role = RoleEnum.Member;

    if (this.site.isSiteCreated) {
      this.loadMembers();
    } else {
      this.lot.serviceProvider = this.account.organisation.identity;
      this.lot.serviceProviderName = this.account.organisation.identity.name;
      this.lot.included = true;
      this.lot.role = this.role = RoleEnum.Manager;
      this.checkLot();
    }

    this.originalLot = { ...this.lot };
    this.organisationLabel = this.lot.organisationLabel;
  }

  deleteLot() {
    this.confirmationService
      .confirm(
        'lot.components.form.title.delete',
        'lot.components.form.labels.deleteGuide',
        this.translateService.instant('app.delete'),
        'confirmation.form.buttons.delete',
        null
      )
      .subscribe((result) => {
        if (result) {
          this.lotCommandResourceService.deleteLot(this.constructionSite.id, this.lot.id).subscribe(
            () => {
              this.router.navigate(['construction-sites', this.constructionSite.id, 'lots']);
              this.eventManager.broadcast(APP_EVENTS.CONSTRUCTION_SITE_UPDATED);
              this.eventManager.broadcast(APP_EVENTS.LOT_LIST_UPDATED);
              this.toasterService.success(this.translateService.instant('lot.components.form.messages.delete-success'));
            },
            () => this.toasterService.error(this.translateService.instant('lot.components.form.messages.delete-error'))
          );
        }
      });
  }

  isEdition(): boolean {
    return Boolean(this.lot && this.lot.id);
  }

  async updateLot() {
    const response = await this.lotService.updateLot(this.lot, this.constructionSite.id);
    this.createResponse(response);
  }

  createResponse(response: HttpResponse<Lot>) {
    if (response.ok) {
      this.lot = response.body;
      this.sendInvitations(response.body);
      this.previousRoute();
      this.eventManager.broadcast(APP_EVENTS.LOT_SHOW_UPDATED);
      this.wip = false;
      this.toasterService.success(this.translateService.instant('lot.components.form.messages.save-success'));
    } else {
      this.toasterService.error(this.translateService.instant('lot.components.form.messages.save-error'));
    }
  }

  async createLot() {
    const response = await this.lotService.createLot(this.lot, this.constructionSite.id);
    this.updateResponse(response);
  }
  updateResponse(response: HttpResponse<Lot>) {
    if (response.ok) {
      this.sendInvitations(response.body);
      this.createNewLot(response.body);
      this.eventManager.broadcast(APP_EVENTS.CONSTRUCTION_SITE_UPDATED);
      this.eventManager.broadcast(APP_EVENTS.LOT_LIST_UPDATED);
      this.eventManager.broadcast(APP_EVENTS.LOT_SHOW_UPDATED);
      this.wip = false;
      this.toasterService.success(this.translateService.instant('lot.components.form.messages.save-success'));
    } else {
      this.toasterService.error(this.translateService.instant('lot.components.form.messages.save-error'));
    }
  }

  createNewLot(lot: Lot) {
    if (!this.addAnotherLot) {
      this.router.navigate(['../', lot.id, 'show'], { relativeTo: this.route });
    } else {
      this.reset();
    }
  }

  saveOrUpdate() {
    this.wip = true;
    this.lot.members = this.lot.members ? this.lot.members.filter((member) => member.email) : [];
    this.lot.fixedPercent = this.lot.fixedPercent ? this.lot.fixedPercent / 100 : this.lot.fixedPercent;
    this.lot.role = this.role;
    if (this.site.isSiteCreated) {
      if (this.isEdition()) {
        this.updateLot();
      } else {
        this.createLot();
      }
      this.updateOrganisationLabel();
    } else {
      (this.lot.members = this.invitedEmails.map((inviteeEmail) => {
        const member = {
          email: inviteeEmail.email.trim(),
          hasToSendInvitation: inviteeEmail.hasToSendInvitation,
          role: RoleEnum.Manager
        } as MemberId;
        return member;
      })),
        this.addSite.emit({
          constructionSite: this.constructionSite,
          lot: this.lot,
          picture: this.site.picture
        });
      this.activeModal.dismiss('close');
      this.wip = false;
    }
  }

  previousRoute() {
    if (this.isEdition()) {
      this.router.navigate(['../', 'show'], { relativeTo: this.route });
    } else {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }

  closeSiteModal() {
    this.activeModal.dismiss();
    this.closeParentModal.emit(true);
  }

  checkLot() {
    if (this.isCurrentUserProvider()) {
      // If selected service provider is the same of current user, add current user to members
      if (!this.lot.members || !this.lot.members.find((memberId) => memberId.id === this.account.id)) {
        // If current user already exists in members, do not add otherwise, add current user to lot members
        this.invitedEmails.push({
          email: this.account.email.trim(),
          isFormatValid: true,
          isMxValid: true,
          isDisposable: false,
          isScoreValid: true,
          hasToSendInvitation: true
        });
      }
    }
  }

  removeMember(member: MemberId, index: number) {
    this.members.splice(index, 1);
    this.revokedMembers.push(member);
    this.lot.members = this.lot.members.filter((memberId) => memberId.id !== member.id);
  }

  private sendInvitations(lot: Lot) {
    let needRefresh = false;

    const revokeObs: Observable<any>[] = [];
    this.revokedMembers.forEach((memberId) => {
      revokeObs.push(this.memberCommandResourceService.deleteMember(this.constructionSite.id, memberId.id, lot.id));
      needRefresh = true;
    });
    forkJoin(revokeObs).subscribe(
      () => {},
      () => {
        this.toasterService.error(this.translateService.instant('member.components.list.messages.member-delete-error'));
      }
    );

    const emails = this.invitedEmails.filter((email) => {
      email.email = email.email.trim().toLowerCase();
      return email;
    });

    /* istanbul ignore next */
    if (emails && emails.length) {
      this.memberQueryResourceService.getMembers(this.constructionSite.id).subscribe((members) => {
        needRefresh = true;
        const invitations: Invitations = { invitations: [] };
        invitations.organisation = lot.serviceProvider;
        invitations.invitations = emails.map((email) => {
          const invitation: Invitation = { email: null };
          invitation.email = email.email.trim();
          invitation.role = this.role;
          invitation.hasToSendInvitation = email.hasToSendInvitation;
          return invitation;
        });

        this.lotMemberCommandResourceService
          .sendLotInvitations(this.constructionSite.id, lot.id, invitations)
          .subscribe(
            () => {
              this.eventManager.broadcast(APP_EVENTS.CONSTRUCTION_SITE_UPDATED);
              this.eventManager.broadcast(APP_EVENTS.LOT_LIST_UPDATED);
              this.eventManager.broadcast(APP_EVENTS.LOT_SHOW_UPDATED);
              this.toasterService.success(
                this.translateService.instant('construction-site.components.form.messages.invitation-success')
              );
            },
            () =>
              this.toasterService.error(
                this.translateService.instant('construction-site.components.form.messages.invitation-error')
              )
          );
      });
    }

    if (needRefresh) {
      this.eventManager.broadcast({ name: APP_EVENTS.CONSTRUCTION_SITE_UPDATED, content: this.lot });
    }
  }

  private isCurrentUserProvider(): boolean {
    return this.lot.serviceProvider && this.lot.serviceProvider.id === this.account.organisation.organisationId;
  }

  private reset() {
    this.lot = { name: null, description: null, role: null };
    this.lot.included = true;
    this.addAnotherLot = false;
    this.members = [];
    this.invitedEmails = [];
    this.form.form.markAsPristine();
    this.form.form.markAsUntouched();
  }

  private loadMembers() {
    if (this.lot.id) {
      this.lotMemberQueryResourceService.getLotMembers(this.constructionSite.id, this.lot.id).subscribe((members) => {
        this.members = members;
        this.role = this.getDefaultMemberRole(this.lot.members);
      });
    }
  }

  areEmailsEmpty(
    invitedEmails: {
      email: string;
      isFormatValid: boolean;
      isMxValid: boolean;
      isDisposable: boolean;
      hasToSendInvitation: boolean;
    }[]
  ) {
    return invitedEmails.some((email) => !email.email);
  }

  confirmSaveOrUpdate() {
    this.wip = true;
    this.confirmationService
      .confirm(
        'lot.components.form.role.confirmation.title',
        'lot.components.form.role.confirmation.guide',
        this.translateService.instant('app.confirm'),
        'lot.components.form.buttons.' + (this.isEdition() ? 'edit' : 'add'),
        null
      )
      .subscribe((result) => {
        if (result) {
          this.saveOrUpdate();
        }
      });
  }

  updateOrganisationLabel() {
    if (this.organisationLabel !== this.originalLot.organisationLabel) {
      const sirenManagerLabel = {
        name: this.lot.serviceProvider.name,
        organisationSiren: this.lot.serviceProvider.parentRegistrationId,
        label: this.organisationLabel
      } as SirenManagerLabel;
      this.organisationRegistrationCommandResourceService
        .updateOrganisationRegistrations({ list: [sirenManagerLabel] })
        .subscribe(() => {});
    }
  }
}
