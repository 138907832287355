/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Invitation { 
    role?: Invitation.RoleEnum;
    hasToSendInvitation?: boolean;
    email: string;
}
export namespace Invitation {
    export type RoleEnum = 'MANAGER' | 'MEMBER' | 'PROJECT_OWNER' | 'GENERAL_CONTRACTOR';
    export const RoleEnum = {
        Manager: 'MANAGER' as RoleEnum,
        Member: 'MEMBER' as RoleEnum,
        ProjectOwner: 'PROJECT_OWNER' as RoleEnum,
        GeneralContractor: 'GENERAL_CONTRACTOR' as RoleEnum
    };
}


