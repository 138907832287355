/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MailjetEvent } from './mailjetEvent';


export interface MemberId { 
    id?: string;
    login?: string;
    constructionSiteId?: string;
    email?: string;
    isPending?: boolean;
    hasToSendInvitation?: boolean;
    role: MemberId.RoleEnum;
    status?: MemberId.StatusEnum;
    notificationEvent?: string;
    notificationEvents?: Array<MailjetEvent>;
}
export namespace MemberId {
    export type RoleEnum = 'MANAGER' | 'MEMBER' | 'PROJECT_OWNER' | 'GENERAL_CONTRACTOR';
    export const RoleEnum = {
        Manager: 'MANAGER' as RoleEnum,
        Member: 'MEMBER' as RoleEnum,
        ProjectOwner: 'PROJECT_OWNER' as RoleEnum,
        GeneralContractor: 'GENERAL_CONTRACTOR' as RoleEnum
    };
    export type StatusEnum = 'MANAGER' | 'MEMBER' | 'PROJECT_OWNER' | 'GENERAL_CONTRACTOR';
    export const StatusEnum = {
        Manager: 'MANAGER' as StatusEnum,
        Member: 'MEMBER' as StatusEnum,
        ProjectOwner: 'PROJECT_OWNER' as StatusEnum,
        GeneralContractor: 'GENERAL_CONTRACTOR' as StatusEnum
    };
}


